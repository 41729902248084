import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { config } from '../shared/constants';
import { FormGroup } from '@angular/forms';
import { CitiesByState, ReviewFinding } from './orders.interface';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private dataSubject = new Subject<ReviewFinding|null>();
  constructor(private http: HttpClient) {}
  sendFocus(data: ReviewFinding|null) {
    this.dataSubject.next(data);
  }

  getFocus() {
    return this.dataSubject.asObservable();
  }

  getOrderListing(data: any): Promise<any> {
    const params = new HttpParams({ fromObject: data });
    const options = { params };
    return this.http.get(`orders/`, options).toPromise();
  }

  getOrderListingStatusCount(): Promise<any> {
    return this.http.get(`orders/count/status`).toPromise();
  }

  exportOrdersAsCSV(data: any, ids: string[]): Promise<any> {
    const params = new HttpParams({ fromObject: data });
    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'blob';
      withCredentials?: boolean;
    } = {
      responseType: 'blob',
      params: params
    };
    return this.http.post(`orders/download_csv`, ids, options).toPromise();
  }

  downloadPDF(orderId: string): Promise<any> {
    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'blob';
      withCredentials?: boolean;
    } = {
      responseType: 'blob',
    };
    return this.http.get(`orders/download_stip_list/${orderId}`, options).toPromise();
  }

  sendEmail(data: any, emails: string[]) {
    const params = new HttpParams({ fromObject: data });
    const options = { params };
    return this.http.post(`orders/share_stiplist`, emails, options).toPromise();
  }

  getClients() {
    return this.http.get(`clients/`).toPromise();
  }

  createReview(data: any, loanType: string, purposeType: string): Promise<any> {
    return this.http.post(`reviews/?loan_type=${loanType}&purpose_type=${purposeType}`, data).toPromise()
  }

  convertObjectInFormData(formValue: any) {
    const params: FormData = new FormData();
    for (const key in formValue) {
      // eslint-disable-next-line no-prototype-builtins
      if (formValue.hasOwnProperty(key)) {
        const value = formValue[key];
        if (value instanceof FileList) {
          for (let i = 0; i < value.length; i++) {
            params.append(key, value[i]);
          }
        } else {
          params.append(key, value);
        }
      }
    }
    return params;
  }

  getOrderDetail(orderId: string, token?: string): Observable<any> {
    return this.http.get<any>(`orders/${orderId}${token ? `/${config.magicLink.token}${token}` : ''}`);
  }
  getOrderHtml(reviewId:string, token?: string) {
    return this.http.get(`reviews/fetch_html${token ? `/${config.magicLink.token}${token}` : ''}?mock=false&review_id=${reviewId}`);
  }
  addManualFinding(data: any): Observable<any> {
    return this.http.post<any>(`review_findings/`, data);
  }

  editFindings(reviewFindingId: string, data: any, token?: string): Observable<any> {
    return this.http.patch<any>(`review_findings/${reviewFindingId}${token ? `/${config.magicLink.token}${token}` : ''}`, data);
  }

  getReviewDocuments(revisionNumber: number, orderNo: string, token?: string, slug?: boolean): Observable<any> {
    if (slug) {
      const url = `orders/additional_docs/${token ? `/${config.magicLink.token}${token}` : ''}${orderNo}`;
      return this.http.get<any>(url);
    } else {
      const url = `documents/document${token ? `/${config.magicLink.token}${token}` : ''}?revision_number=${revisionNumber}&order_number=${orderNo}`;
      return this.http.get<any>(url);
    }
  }

  getAdditionalDocuments(orderNo: string, token?: string): Observable<any> {
    const url = `orders/appraisal_scope/additional_docs${token ? `/${config.magicLink.token}${token}` : ''}?order_number=${orderNo}`;
    return this.http.get<any>(url);
  }

  downloadReviewDocumnets(fileName: string): Observable<any> {
    const url = `documents/download?filename=${fileName}`;
    return this.http.get(url);
  }

  getReviewers(params = '') {
    return this.http.get(`reviewers/${params}`).toPromise();
  }

  getOrderStatues() {
    return this.http.get(`order_statues/`).toPromise();
  }

  getOrderStatuesCount() {
    return this.http.get(`order_statues/order-status-counts`).toPromise();
  }

  updateReviewer(data: any): Observable<any> {
    return this.http.put<any>(`reviews/?order_number=${data.order_number}&reviewer_uid=${data.external_id}`, []);
  }

  uploadDocument(reviewId: string, fileType: string, data: any, is_Additional: boolean): Observable<any> {
    const encodedfileType = encodeURIComponent(fileType); // handling 'Appraiser E&O Document' case
    return this.http.post<any>(`documents/upload?review_id=${reviewId}&file_type=${encodedfileType}&is_additional=${is_Additional}`, data)
  }

  setColumnPreference(data: any): Promise<any> {
    return this.http.post('orders/user-order-column', data).toPromise()
  }

  updateOrders(orderId: string, status: string, data: any): Observable<any> {
    return this.http.patch<any>(`orders/${orderId}?order_status=${status}`, data);
  }

  filterCitiesOnSelectedState(formGroup: FormGroup, citiesByStates: CitiesByState) {
    formGroup.get('city')?.setValue('');
    return citiesByStates[formGroup.get('state')?.value];
  }

  filterCitiesOnSearch(cityValue: any, formGroup: FormGroup, citiesByStates: CitiesByState) {
    if (cityValue) {
      return citiesByStates[formGroup.get('state')?.value]?.filter((city: any) =>
        city.toLowerCase().startsWith(cityValue.toLowerCase()),
      );
    }
    return citiesByStates[formGroup.get('state')?.value];
  }

  latestRevision(arrObj: any, revisionNo?: boolean) {
      const maxRevision = this.getMaxRevision(arrObj);
      if (arrObj.length > 0 && maxRevision?.assigned_to_reviewer) {
        return this.displayRevision(maxRevision, revisionNo);
      } else if (arrObj.length > 0 && maxRevision?.review_version_number && revisionNo) {
        return maxRevision.review_version_number;
      } else {
        return revisionNo ? 0 : '';
      }
  }

  getMaxRevision(arrObj: any) {
    return arrObj.reduce((max: any, obj: any) => max.review_version_number > obj.review_version_number ? max : obj);
  }

  displayRevision(maxRevision:any, revisionNo?: boolean) {
    const displayName =  maxRevision.assigned_to_reviewer.user.last_name ? `${maxRevision.assigned_to_reviewer.user.first_name} ${maxRevision.assigned_to_reviewer.user.last_name}`
    : `${maxRevision.assigned_to_reviewer.user.first_name}`;
    return revisionNo ? maxRevision.review_version_number : displayName;
  }

  getOrdersOnNext() {
    return this.http.get(`orders/next_order/`).toPromise();
  }

  checkOrderNext() {
    return this.http.get(`orders/next_check/`).toPromise();
  }

  submitToEAD(orderId: string, data?: any): Promise<any> {
    return this.http.post(`orders/submit_ead/${orderId}`, data).toPromise();
  }

  submitToUCDP(orderId: string, data?: any): Promise<any> {
    return this.http.post(`orders/submit_ucdp/${orderId}`, data).toPromise();
  }

  getSubForms(reviewId: string) {
    return this.http.get(`reviews/get_review_sub_forms?review_id=${reviewId}`).toPromise();
  }

  getCitiesAndStates(): Promise<any> {
    return this.http.get(`states-cities/`).toPromise();
  }

  addCitiesByStates(data: any): Promise<any> {
    return this.http.post(`states-cities/`, data).toPromise();
  }

  getOverRideRequestCodes(type: string): Promise<any> {
  // return this.http.get(`override_request_code/{type}?&override_request_code_type=${type}`).toPromise();
    return this.http.get(`override_request_code/`).toPromise();
  }

  updateFindingsInBulk(data: any):  Promise<any> {
    return this.http.post(`review_findings/bulk`, data).toPromise();
  }

  updateReviewerInBulk(data: any): Observable<any> {
    return this.http.put<any>(`reviews/bulk?reviewer_uid=${data.reviewerId}`, data.orderIds);
  }

  scrollToFinding(inputElement: HTMLElement, containerElement: HTMLElement) {
    const viewportHeight = containerElement.clientHeight;
    const currentScrollPosition = containerElement.scrollTop;
    const elementRect = inputElement.getBoundingClientRect();
    const desiredScrollPosition = currentScrollPosition + elementRect.top - (viewportHeight / 2) - 250;
    containerElement.scrollTo({
      top: desiredScrollPosition,
      behavior: 'smooth' // Optional: Add smooth scrolling effect
    });
  }

  getOrderHistory(orderId: string, token?: string): Observable<any> {
    return this.http.get<any>(`orders/history/${orderId}${token ? `/${config.magicLink.token}${token}` : ''}`);
  }

  getOrderRevisionHistory(orderId: string): Promise<any> {
    return this.http.get(`revision_messages/${orderId}`).toPromise();
  }

  deleteUCDPappraisal(orderId: string): Promise<any> {
    return this.http.post(`orders/delete_ucdp/${orderId}`, null).toPromise();
  }

  deleteEADappraisal(orderId: string): Promise<any> {
    return this.http.post(`orders/delete_ead/${orderId}`, null).toPromise();
  }

  getRevisionList(orderId: string, token?: string): Observable<any> {
    return this.http.get<any>(`revision_type/${token ? `/${config.magicLink.token}${token}` : ''}`);
  }

  sendRevisionToAppraiserOrClient(data: any, token?: string):  Promise<any> {
    return this.http.post(`revision_messages/${token ? `/${config.magicLink.token}${token}` : ''}`, data).toPromise();
  }

}
